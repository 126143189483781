
const GET_CAREER = 'GET_CAREER'
const GET_EXPIRIENCES = 'GET_EXPIRIENCES'

export default function Reducer(state, action) {
    const {payload, type} = action

  switch (type){
    case GET_CAREER:
            return {...state, educationC: payload}
    case GET_EXPIRIENCES:
      return {...state, expiriences: payload}
  }
}

