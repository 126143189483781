import React, { useReducer, useState, useEffect } from 'react'
import { initializeApp } from "firebase/app";
import {getDatabase, ref, onValue} from 'firebase/database';
import Contexto from './Contexto';
import Reducer from './Reducer';
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";


export default function UseContexto(props) {
// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCZwrxS5_WlmSwjRAU3yqdUXRU9fg-NWAc",
  authDomain: "sandovalguuada.firebaseapp.com",
  databaseURL: "https://sandovalguuada-default-rtdb.firebaseio.com",
  projectId: "sandovalguuada",
  storageBucket: "sandovalguuada.appspot.com",
  messagingSenderId: "66281178846",
  appId: "1:66281178846:web:3ca23563cbb171bde2644f"
};
const {children} = props
const initState ={
    educationC: [],
    expiriences: [],
  }
const [state, dispatch] = useReducer(Reducer, initState)
const [currentUser, setCurrentUser] = useState(null);

// Initialize Firebase
const appp = initializeApp(firebaseConfig);
const db = getDatabase()
const auth = getAuth(appp);
const refEd = ref(db, "education/")
const refExp = ref(db, "expiriences/")


signInAnonymously(auth)
  .then(() => {
    // Signed in..
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // ...
  });
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/firebase.User
      const uid = user.uid;
      // ...
    } else {
      // User is signed out
      // ...
    }
  });

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });
  }, []);
  
const getEducation = async ()=>{
    onValue(refEd, (snap)=>{ 
        dispatch({type: 'GET_CAREER', payload: snap.val()})
    })
  }
const getExpiriences = async ()=>{
  onValue(refExp, (snap) =>{
    dispatch({type:'GET_EXPIRIENCES', payload: snap.val()})
  })
}
  return (
     <Contexto.Provider value={{currentUser, educationC: state.educationC, expiriences: state.expiriences, getEducation, getExpiriences}}>
      {children}
    </Contexto.Provider>
  )
}
