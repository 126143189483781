import React from 'react'
import { Link } from 'react-router-dom'
import {ReactComponent as Logo} from '../assets/static/logo.svg'
import "../assets/css/Header.css"
import {motion} from 'framer-motion'
import file from '../assets/static/fullstack-sandoval.pdf'

export default function Header() {

  return (
    <>
    <motion.div className='nav'
    initial={{y:-250}}
    animate={{y:0}}
    transition={{
      delay: 0.2,
      type: 'spring',
      stiffness: 120
    }}
    >
    <Link to="/"><Logo className='nav-logo' fill='#000' /></Link>
    <div>
      <a id='file' href={file} download='fullstack-sandoval'>curriculum vitae</a>
    </div>
        <div className='nav-pages'>
            <Link to="/skill" className='text-link'>PROYECTOS</Link>
            <Link to="/contact" className='text-link'>CONTACTO</Link>
        </div>
    </motion.div>
    </>
  )
}
