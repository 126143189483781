import React from 'react'
import Item from './Item';
import "../assets/css/Skill.css"
import gopher from '../assets/static/gopher.png'
import react from '../assets/static/react.png'
import angular from '../assets/static/angular.png'
export default function Skill() {

  const projects = [  
    {
      tittle: 'Fullstack Open',
      description: 'Aplicaciones de una sola página con ReactJS que utilizan API REST creadas con Node.js',
      date: '2024',
      link: 'https://github.com/guadasandoval/fullStackOpen'
    },
    {
    tittle: 'Animacion JS',
    description: 'Proyecto final de curso de Yannick Gregorie',
    date: '2024',
    link: 'https://mentastudio.vercel.app/'
  },
  {
    tittle: 'Planify',
    description: 'Página de reservas. Desarrollado con ReactJS, Tailwind CSS y Typescript',
    date: '2024',
    link: 'https://github.com/guadasandoval/planify'
  },
  {
    tittle: 'Oráculo',
    description: 'Proyecto personal creado para una muestra anual de cerámica',
    date: '2023',
    link: 'https://oraculo-sigma.vercel.app/'
  },
  {
    tittle: 'Aerolab Challenge',
    description: 'App de ecommerce. Desarrollado con ReactJS y Tailwind CSS',
    date: '2023',
    link: 'https://aerolab-sepia.vercel.app/'
  },
]

  return (
    <>  
    <div className="skill-container">
      <div>
        <h1 className='skill-h1 font-sec'>Proyectos</h1>
      </div>
      <div>
        <p>Proyectos personales, ui mockups, trabajos finales y playground</p>
      </div>
      <img id='gopher' src={gopher} alt=""/>
      <img id='angular' src={angular} alt="" />
      <img id='react' src={react} alt="" />
      <div className='projects-list'>
        {projects.map((item, i)=>(
          <Item {...item} key={i}></Item>
        ))}
      </div>
    </div>
    </>
  )
}
