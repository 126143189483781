import React from 'react'
import "../assets/css/Contact.css"
import name from '../assets/static/name.svg'
import {ReactComponent as Flower} from '../assets/static/flower.svg'
import {motion} from 'framer-motion'

export default function Contact() {
  return (
    <>
    <div className="contact-section">
      <div className="contact-container">
        <div className="header-contact">
          <div className="div-column">
            <p id='bs'>Buenos Aires, Argentina</p>
          </div>
      <div className='div-column'> 
        <p>HOLA!</p>
        <a href="mailto:guuadasandoval@gmail.com" className='underline'>
          <span>guuadasandoval@gmail.com</span></a>
      </div>
      </div>
      <div className='div-center'>
      <motion.div
      initial={{x:'-100vw'}}
      animate={{x:0}}
      transition={{
        delay:1.5,
        type: 'spring',
        stiffness:120,
        duration:1,
      }}
      >
        <img src={name} alt="" />
      </motion.div>
      <Flower style={{width:800}}></Flower>
      <Flower style={{width:800}}></Flower>
      </div>
       <hr className='contact-hr'/>    
       <div className="div-links">
        <p>GUADALUPE SANDOVAL©2024</p>
        <a  href="https://github.com/guadasandoval" target="_blank" rel="noreferrer">GITHUB</a> 
        <a  href="https://www.behance.net/guadalupesandoval" target="_blank" rel="noreferrer">BEHANCE</a> 
        <a  href="https://www.linkedin.com/in/guadasandoval/" target="_blank" rel="noreferrer">LINKEDIN</a>
    </div>
      </div>
      </div>
    </>
  )
}
