import React from 'react'
import '../assets/css/Item.css'
import {motion} from 'framer-motion'

export default function Item(props) {
    const {tittle, description, date, link, i} = props
  return (
    <>
        <motion.div className='item-container' key={i}  
        whileHover={{scale:1.1, originX:0, color:'#ddfe55'}}
            transition={{
            type:'spring',
            stiffness: 120}}>
            <div    
            className='item-align'>
            <p>{date}</p>
            <a href={link} className='item-a'>{tittle}</a>
            <h2>{description}</h2> 
            <hr/>
            </div>
        </motion.div>
    </>
  )
}
