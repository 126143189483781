import React from 'react'
import "../assets/css/Home.css"
import Contact from '../components/Contact'
import Skill from '../components/Skill'
import {motion} from 'framer-motion'
import avatar from '../assets/static/avatar.png'


export default function Home() {
  return (
    <>
   <div className="home-container">
    <div className='box-above'>
      <p>(🇦🇷)</p>
        <p>Guadalupe Sandoval</p>
    </div>
   <div className='box-row'>
   <p className='box-design'>1993</p> 
    <p className='box-design'>UI design</p>
    <p className='box-design'>Web design</p>
    <p className='box-design'>2024</p>
    <div className="box-text">
      <motion.p className="home-tittle" 
      initial={{opacity:0, x:'100vw'}}
      animate={{x:0, opacity:1}} 
      transition={{
        delay:0.5,
        duration:1}}
      dragMomentum={true}>Fullstack developer</motion.p>
    </div>

   </div>
        
    <div>
    </div>
    <div className='box-below'>
    <div className='nav-pages'>
      <a href="https://www.linkedin.com/in/guadasandoval/" className='text-link'>LinkedIn</a>
      <a href="https://github.com/guadasandoval" className='text-link'>Github</a>
      <a href="https://medium.com/@guadastoop" className='text-link'>Medium</a>
    </div>
      <img src={avatar} alt="" />
    </div>
      
   </div>
  <Skill></Skill>
  <Contact></Contact>
  </>
  )
}
